<template>
  <div class="container">
    <div class="row d-flex justify-content-end mg-b-15">
      <router-link :to="{name: 'admin.churches.create'}" class="btn btn-primary">{{ $t('churches.create') }}</router-link>
    </div>

    <div class="row">
      <div class="placeholder-paragraph aligned-centered" v-if="loading">
        <div class="line"></div>
        <div class="line"></div>
      </div>

      <table class="table" v-if="!loading">
        <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Invite Code</th>
          <th scope="col">Published</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="church in churches" v-bind:key="church.id">
          <td>
            <router-link :to="{name: 'admin.churches.edit', params: {church_id: church.id}}">{{ church.name }}</router-link>
          </td>
          <td>{{church.code}}</td>
          <td v-if="!church.published_at">
            <button class="btn btn-secondary btn-xs" v-on:click="publish(church)">Publish</button>
          </td>
          <td v-if="church.published_at">
            {{church.published_at|format_date}}
          </td>
          
          <td>
            <fa :icon="['fa', 'trash-alt']" v-on:click="deleteChurch(church)"/>
          </td>
        </tr>
        </tbody>
      </table>

      <Pagination v-bind:meta="meta" v-on:pageChanged="onPageChanged"></Pagination>
    </div>
  </div>
</template>

<script>
  // import { mapGetters } from 'vuex'
  import axios from 'axios'
  import Pagination from '~/components/Pagination'

  export default {
    middleware: 'auth',
    components: {
      Pagination 
    },
    data: () => ({
      loading: true,
      meta: {},
      limit: 30,
      page: 1,
      churches: []
    }),
    created() {
      this.refresh();
    },
    methods: {
      refresh() {
        this.loading = true;
        this.$store.dispatch('admin/fetchChurches', {limit: this.limit, page: this.page})
          .then((data) => {
            this.loading = false; 
            this.meta = data.meta;
            this.churches = data.churches;
          });
      },
      onPageChanged (page) {
        this.page = page;
        this.refresh();
      },
      async publish(church) {
        try{
          await axios.post('/api/v1/admin/churches/' +  church.id + '/publish');
          this.refresh();
        } catch (e) {
          console.log();
        }
      },
      async deleteChurch(church) {   
        try{
          await axios.delete('/api/v1/admin/churches/' +  church.id);
          this.refresh();
        } catch (e) {
          // this.$router.push({name: 'accounts.church.invite'});
        }
      }
    }
  }
</script>
